import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Link } from "gatsby"

import Cookies from "../components/Cookies"
import OwnLink from "../components/Link"

import "../css/index.css"

export default function Index({ data }) {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <Layout>
      <Cookies />
      <div className="blog-posts">
        {posts
          .filter((post) => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            return (
              <div className="blog-post-preview" key={post.id}>
                <h1 className="title">
                  <Link to={post.frontmatter.path} className="post-title-link">
                    {post.frontmatter.title}
                  </Link>
                </h1>
                <h6 className="date">{post.frontmatter.date}</h6>
                <p>{post.excerpt}</p>
                <OwnLink to={post.frontmatter.path}>Read more</OwnLink>
              </div>
            )
          })}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            tags
          }
        }
      }
    }
  }
`
