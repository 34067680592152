import React, { Component } from "react"
import styled from "styled-components"
import { FaWindowClose, FaRegShareSquare } from "react-icons/fa"

const Banner = styled.div`
  background-color: blue;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 10px;
  height: 50px;
  padding: 5px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0;
  display: ${(props) => (props.shown ? "flex" : "none")};
`

export default class Cookies extends Component {
  state = { shown: true }
  componentDidMount() {
    // loadCookie
  }
  loadCookie = () => {}
  storeCookie = () => {
    console.log("storing")
  }
  hide = () => this.setState({ shown: false }, this.storeCookie())
  render() {
    return (
      <Banner shown={this.state.shown}>
        <span>
          Cookie 🍪 blabla. GDPR blabla. If you ❤️ this, you can add this site
          to your Homescreen (<FaRegShareSquare />
          )😂
        </span>
        <FaWindowClose onClick={this.hide} />
      </Banner>
    )
  }
}
